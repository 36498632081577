<template>
  <router-link to="/contests" v-if="contest" class="trading__tag">
    <Status :text="i18n.t('statuses.trading_account.contest')" status="closed" />
  </router-link>
</template>

<script>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import Status from './Status.vue';

export default {
  name: 'TradingContestTag',
  props: {
    contest: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const i18n = reactive(useI18n());
    return {
      i18n,
    };
  },
  components: { Status },
};
</script>

<style></style>
